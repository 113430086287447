<template>
<div class="about">
  <div class="page-title-wrap">
    <h1>Proyectos</h1>
  </div>

    <div class="content-wrap-container">
    <div class="row mt-3">
      <div class="col-md-12 text-end">
        <a class="btn btn-primary" @click="$router.push('ProjectInformation')">
          <i class="fa fa-plus"> </i> Crear Proyecto
        </a>
      </div>
      <projects-table class="text-start mt-4" />
    </div>
  </div>
</div><!--end content-wrap-container-->
</template>
<script>
import ProjectsTable from "@/components/Project/ProjectsTable.vue";
export default {
  components: { ProjectsTable },
  name: "Home",
  async mounted() {},
};
</script>
